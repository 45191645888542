import React from 'react';
import {useInput} from "../../hooks/input-hook";
import Info from "../global/Info";
import {useRef} from "react";
import {album_api} from "../../api/album_api";

// Component (here it's a view)
function EditAlbumForm(props) {
    // Updatable variable
    const { value:name, bind:bindName } = useInput(props.album.name);

    const infoRef = useRef();

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        const url = name.toString().toLowerCase().replace(/ /g, '-');

        try {
            let result = await album_api.updateAlbum(props.album.id, name, url);
            if (result === 204) {
                infoRef.current.seeInfo("green", "Album mis à jour !");
                setTimeout(() => {
                    props.sended();
                }, 2000);
            } else {
                infoRef.current.seeInfo("red", "Erreur lors de la mise à jour de l'album !");
            }
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="text-2xl text-center my-5">Ajouter un album photo</div>
                <div className="w-full mb-4">
                    <label>
                        <span className="text-gray-700">Nom de l'album</span>
                        <input className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Un super nom..." {...bindName} />
                    </label>
                </div>
                <div className="mb-5">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Modifier l'album
                    </button>
                </div>
            </form>
            <div className={"mt-5"}>
                <Info ref={infoRef}/>
            </div>
        </div>
    );
}

export default EditAlbumForm;