import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Home from "./views/Home";
import News from "./views/News";
import Post from "./views/Post";
import Navbar from "./components/global/Navbar";
import Login from "./views/Login";
import Footer from "./components/global/Footer";
import Schedule from "./views/Schedule";
import Contact from "./views/Contact";
import Albums from "./views/Albums";
import Album from "./views/Album";
import Tournament from "./views/Tournament";
import Tournaments from "./views/Tournaments";
import TournamentPaper from "./views/TournamentPaper";
import Header from "./components/global/Header";
import HeaderHome from "./components/home/Header";
import Register from "./views/Register";
import Announcement from "./components/global/Announcement";
import Events from "./views/Events";
import Partner from './components/global/Partner';

function App() {
    const location = useLocation();

  return (
      <div>
          {location.pathname === '/' &&
            <div>
                <Navbar color={'#3f91fc'}></Navbar>
                <div className={"mt-navbar"}></div>
                <Announcement></Announcement>
            </div>
          }
          {location.pathname !== '/' && !location.search.includes("?view") &&
            <div>
                <Navbar></Navbar>
                <div className={"mt-navbar"}></div>
                <Announcement></Announcement>
            </div>
          }
          <Routes>
            <Route path='/' element={<Header children={<HeaderHome/>}/>}></Route>
          </Routes>
          <div className="mx-9 py-5">
              <div className="max-w-lg my-0 mx-auto">
                  <Routes>
                      <Route path='/' element={<Home/>}></Route>
                      <Route path='/login' element={<Login/>}></Route>
                      <Route path='/actus' element={<News/>}></Route>
                      <Route path='/horaires' element={<Schedule/>}></Route>
                      <Route path='/inscription' element={<Register/>}></Route>
                      <Route path='/contact' element={<Contact/>}></Route>
                      <Route path='/albums' element={<Albums/>}></Route>
                      <Route path='/albums/:album' element={<Album/>}></Route>
                      <Route path='/actus/:post' element={<Post/>}></Route>
                      <Route path='/tournois/:tournament' element={<Tournament/>}></Route>
                      <Route path='/tournois' element={<Tournaments/>}></Route>
                      <Route path='/evenements' element={<Events/>}></Route>
                      <Route path='/tournois/:tournament/display' element={<TournamentPaper/>}></Route>
                  </Routes>
              </div>
          </div>
          { !location.search.includes("?view") && <div className="bg-blue-500 px-9">
              <div className="max-w-lg my-0 mx-auto sm:flex py-5 justify-between space-y-3 sm:space-y-0">
                  <div className="text-white text-center content-center font-bold font-outfit text-xl">
                      <a href="https://www.facebook.com/profile.php?id=61557203085585" target="_blank">
                          SUIVEZ-NOUS SUR LES RESEAUX SOCIAUX
                      </a>
                  </div>
                  <div className="justify-center flex sm:flex-0 content-center">
                      <a href="https://www.facebook.com/profile.php?id=61557203085585" target="_blank">
                          <img src="/img/icon-facebook.svg" alt="Logo noir du club de badminton de l'ASFlavigny"/>
                      </a>
                  </div>
              </div>
          </div> }
          
          { !location.search.includes("?view") && <Partner></Partner> }
  
          {!location.search.includes("?view") &&
            <Footer></Footer>
          }
      </div>
  );
}

export default App;
