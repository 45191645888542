import React, {useRef} from "react";
import PopUp from "../global/PopUp";
import DeleteEventForm from "../form/DeleteEventForm";
import EditEventForm from "../form/EditEventForm";


function EventProp(props) {

    const popUpDel = useRef();
    const popUpEdit = useRef();
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];

    const {event, selectedEvent} = props;
    return (
        <div className={"relative sm:flex sm:justify-between"}>
            <div>
                <PopUp ref={popUpDel} children={<DeleteEventForm id={event.id} sended={() => {popUpDel.current.closePopup();}} />}/>
                <PopUp ref={popUpEdit} children={<EditEventForm event={event} sended={() => {popUpEdit.current.closePopup();}} />}/>
                {localStorage.getItem('username') !== null &&
                    <div className={"flex space-x-2 mb-2"}>
                        <div className={"bg-red-500 px-1 text-white rounded-full font-extrabold cursor-pointer"}
                                onClick={() => popUpDel.current.seePopup()}>SUPPRIMER</div>
                        <div className={"bg-orange-500 px-1 text-white rounded-full font-extrabold cursor-pointer"}
                                onClick={() => popUpEdit.current.seePopup()}>MODIFIER</div>
                    </div>
                }
                <div className={"block sm:hidden absolute right-0"}>
                    <p className={"font-bold font-outfit"}>
                        {new Date(event.date).toLocaleDateString()}
                    </p>
                </div>
                <div>
                    <span className="whitespace-nowrap rounded-full px-2.5 py-0.5 text-sm " style={{backgroundColor: event.color}}>
                        {event.type}
                    </span>
                </div>
                <h4 className={"mt-2 text-xl font-bold font-outfit"}>
                    {event.name.toUpperCase()}
                </h4>
                {selectedEvent === event.id && <div className="mt-4 text-justify whitespace-pre-line">
                    <p dangerouslySetInnerHTML={{__html: event.description}}></p>
                </div>}
                {selectedEvent === event.id && event.link !== "" &&
                    <a href={event.link} className={"mt-2 text-sm text-blue-500 hover:underline"}>En savoir plus</a>
                }
            </div>
            <div className={"h-20 w-20 bg-gray-200 hidden sm:block"}>
                <p className={"text-center text-5xl font-bold font-outfit"}>
                    {new Date(event.date).getDate()}
                </p>
                <p className={"text-center font-bold font-outfit"}>
                    {months[new Date(event.date).getMonth()].substring(0, 4)}.
                </p>
            </div>
        </div>
    )
}

export default EventProp;