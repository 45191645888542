import React, {useEffect, useRef, useState} from 'react';
import PhotoProp from "../components/albums/PhotoProp";
import {photo_api} from "../api/photo_api";
import {video_api} from "../api/video_api";
import {useParams} from "react-router-dom";
import PopUp from "../components/global/PopUp";
import PhotoForm from "../components/form/PhotoForm";
import VideoForm from "../components/form/VideoForm";
import {album_api} from "../api/album_api";
import {Helmet} from "react-helmet";
import VideoProp from "../components/albums/VideoProp";

function Album () {

    const { album } = useParams()

    const [allphotos, setPhotos] = useState([]);
    const [allvideos, setVideos] = useState([]);
    const [currentAlbum, setAlbum] = useState([]);
    const photos = allphotos.map((photo) =>
        <PhotoProp key={photo.id} photo = {photo} album = {currentAlbum.name}/>
    );
    const videos = allvideos.map((video) =>
        <VideoProp key={video.id} video = {video} album = {currentAlbum.name}/>
    );

    const popUpRef = useRef();
    const popUpVideo = useRef();

    const [limit, setLimit] = useState(5); // Number of news to fetch [default: 5
    const [offsetPhotos, setOffsetPhotos] = useState(0); // Number of news to skip [default: 0]
    const [offsetVideos, setOffsetVideos] = useState(0); // Number of news to skip [default: 0]
    const [hasMorePhotos, setHasMorePhotos] = useState(true); // Boolean to know if there is more news to fetch
    const [hasMoreVideos, setHasMoreVideos] = useState(true); // Boolean to know if there is more news to fetch

    async function fetchPhotosData(offset = 0){
        const resp = await photo_api.getPhotosByAlbumWithLimitAndOffset(album, limit, offset);
        if (resp.length === 0 || resp.length % limit !== 0) {
            await setHasMorePhotos(false);
        }
        // set resp to existing news
        setPhotos([...allphotos, ...resp]);
    }
    async function fetchVideosData(offset = 0){
        const resp = await video_api.getVideosByAlbumWithLimitAndOffset(album, limit, offset);
        if (resp.length === 0 || resp.length % limit !== 0) {
            await setHasMoreVideos(false);
        }
        // set resp to existing news
        setVideos([...allvideos, ...resp]);
    }

    // Function called on load of the component...
    useEffect(async () => {
        // Example of fetch API data
        await fetchPhotosData(offsetPhotos);
        await fetchVideosData(offsetVideos);
        const respAlbum = await album_api.getAlbumByName(album);
        setAlbum(respAlbum);
    }, []);

    const showMorePhotos = () => {
        if (!hasMorePhotos) return;
        setOffsetPhotos(offsetPhotos + limit);
        fetchPhotosData(offsetPhotos + limit);
    }
    const showMoreVideos = () => {
        if (!hasMoreVideos) return;
        setOffsetVideos(offsetVideos + limit);
        fetchVideosData(offsetVideos + limit);
    }

    window.onscroll = function (ev) {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) === document.documentElement.scrollHeight;
        if (bottom) {
            if (photos.length === 0) {
                showMoreVideos();
                return;
            }
            showMorePhotos();
        }
    }

    return(
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{"ASFlavigny - " + currentAlbum.name}</title>
                {currentAlbum.name &&
                    <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                }
                {currentAlbum.name &&
                    <script type="text/javascript">
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'G-GLK66L6C6D');
                        `}
                    </script>
                }
            </Helmet>

            <div className={"text-center mb-5"}>
                <h1 className={"text-4xl font-bold font-outfit"}>{ currentAlbum.name ? currentAlbum.name.toUpperCase() : "" }</h1>
                <p className="text-green-600 font-outfit">
                    {new Date(currentAlbum.date).toLocaleDateString()}
                </p>
            </div>

            <PopUp ref={popUpRef} children={<PhotoForm album_id={currentAlbum.id} sended={() => {popUpRef.current.closePopup();}} />}/>
            <PopUp ref={popUpVideo} children={<VideoForm album_id={currentAlbum.id} sended={() => {popUpVideo.current.closePopup();}} />}/>
            {(videos.length > 0 || localStorage.getItem('username') !== null) &&
                <div>
                    <h2 className={"text-4xl font-medium font-outfit mt-10 mb-5"}>Vidéos</h2>
                    <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"}>
                        {localStorage.getItem('username') !== null &&
                            <div className={"flex"}>
                                <div className={"inline-block relative w-full"}>
                                    <div style={{marginTop: '100%'}}></div>
                                    <div className={"absolute top-0 left-0 right-0 bottom-0"}>
                                        <div onClick={() => popUpVideo.current.seePopup()} className="bg-no-repeat bg-center bg-cover w-full h-full cursor-pointer" style={{backgroundImage: "url('/img/add.jpg')"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {videos}
                    </div>
                </div>
            }
            {(photos.length > 0 || localStorage.getItem('username') !== null) &&
                <div>
                    <h2 className={"text-4xl font-medium font-outfit mt-10 mb-5"}>Photos</h2>
                    <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"}>
                    {
                        localStorage.getItem('username') !== null &&
                        <div className={"flex"}>
                            <div className={"inline-block relative w-full"}>
                                <div style={{marginTop: '100%'}}></div>
                                    <div className={"absolute top-0 left-0 right-0 bottom-0"}>
                                        <div onClick={() => popUpRef.current.seePopup()} className="bg-no-repeat bg-center bg-cover w-full h-full cursor-pointer" style={{backgroundImage: "url('/img/add.jpg')"}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {photos}
                    </div>
                    <div>
                        {hasMorePhotos &&
                            <div className="flex justify-center">
                                <button onClick={() => showMorePhotos()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4">
                                    Voir plus
                                </button>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default Album;