import React from 'react';
import {Helmet} from "react-helmet";

// Component (here it's a view)
function Register() {

    // DOM
    return (
        <div className="mt-6 mb-10">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>ASFlavigny - S'inscrire au club</title>
                <meta name="description" content="Inscrivez-vous au badminton club de Flavigny."/>
                <link rel="canonical" href="/inscription"/>
                <script async src='https://www.googletagmanager.com/gtag/js?id=G-GLK66L6C6D'></script>
                <script type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-GLK66L6C6D');
                    `}
                </script>
            </Helmet>

            <h1 className={"text-2xl font-bold font-outfit mb-4"}>S'incrire au club, rien de plus simple !</h1>
            <p className={"font-black"}>Quelques informations générales sur la licence :</p>
            <li>Prix de la licence : <b>105€</b> (<i>des réductions sont applicables pour les mineurs</i>)</li>
            <li>Accès gratuit aux clubs mutualisés : <b>joueurs de Laxou et Frouard</b></li>
            <li>Modalités de paiement : <b>chèque, espèces ou virement bancaire</b></li>
            <li>Période de validité : <b>du 1er septembre au 31 août</b></li>
            <li>Site de la FFBaD : <a href="https://www.ffbad.org/espaces-dedies/licencies/comment-se-licencier/" className={"text-flavigny underline"}>https://www.ffbad.org/espaces-dedies/licencies/comment-se-licencier/</a></li>
            <p className={"font-black mt-5"}>Quelques documents à remplir pour l'inscription (2024-25) :</p>
            <li><a href="https://frontwebservice.ffbad.org/storage/documents/pages/Pratiquer/Se_licencier/Licence/FFBaD_FORMULAIRE_2024-2025_ADULTES_SAISONNIERE.pdf" className={"text-flavigny underline"}>Formulaire de prise de licence <b>MAJEUR</b></a> (pdf)</li>
            <li><a href="https://frontwebservice.ffbad.org/storage/documents/pages/Pratiquer/Se_licencier/Licence/FFBaD_FORMULAIRE_2024-2025_MINEURS_SAISONNIERE.pdf" className={"text-flavigny underline"}>Formulaire de prise de licence <b>MINEUR</b></a> (pdf)</li>
            <li><a className={"text-flavigny underline"} href="https://echange.ffbad.org/index.php/s/gwxR3oAB8JMWQnc#pdfviewer">Questionnaire médical <b>MAJEUR</b></a> (pdf)</li>
            <li><a className={"text-flavigny underline"} href="https://echange.ffbad.org/index.php/s/fLDrPAzzi3pmNbw#pdfviewer">Questionnaire médical <b>MINEUR</b></a> (pdf)</li>
            <p>Merci de fournir ces documents remplis et signés à un membre du bureau.</p>

            <p className={"text-2xl font-bold font-outfit mt-5"}>Quelques questions fréquentes :</p>
            <div className="space-y-4 mt-3">

                <details
                    className="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                >
                    <summary
                        className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
                    >
                        <h2 className="font-medium">
                            La mutualisation, c'est quoi ?
                        </h2>
                        <span className="relative h-5 w-5 shrink-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                        </span>
                    </summary>
                    <p className="mt-4 leading-relaxed text-gray-700">
                        Laxou, Frouard et Flavigny ont signé une mutualisation. Cela signifie que les licenciés de ces clubs peuvent jouer gratuitement dans les gymnases des autres clubs sur leurs créneaux.
                    </p>
                </details>

                <details
                    className="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                >
                    <summary
                        className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
                    >
                        <h2 className="font-medium">
                            J'ai déjà une licence dans un autre club, est-ce que je dois payer la licence ?
                        </h2>
                        <span className="relative h-5 w-5 shrink-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                        </span>
                    </summary>
                    <p className="mt-4 leading-relaxed text-gray-700">
                        Notre club accepte les doubles licences. Vous n'avez donc pas besoin de payer la licence FFBAD mais uniquement la participation au club de 55€. Vous devez nous fournir une copie de votre licence dans l'autre club.
                        Dans le cadre de la mutualisation, les joueurs des clubs de Laxou et Frouard peuvent jouer gratuitement à Flavigny et inversement.
                    </p>
                </details>

                <details
                    className="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                >
                    <summary
                        className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
                    >
                        <h2 className="font-medium">
                            Je suis mineur (ou mon enfant est mineur), est-ce que je peux m'inscrire ?
                        </h2>
                        <span className="relative h-5 w-5 shrink-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                        </span>
                    </summary>
                    <p className="mt-4 leading-relaxed text-gray-700">
                        Bien sûr ! Les mineurs sous la responsabilité d'un parent ou d'un tuteur légal peuvent s'inscrire au club et bénéficient d'une réduction sur le prix de la licence : 75€ pour les mineurs de moins de 18 ans et 55€ pour les mineurs de moins de 9 ans.
                    </p>
                </details>

                <details
                    className="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                >
                    <summary
                        className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
                    >
                        <h2 className="font-medium">
                            Dois-je fournir un certificat médical ?
                        </h2>
                        <span className="relative h-5 w-5 shrink-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                        </span>
                    </summary>
                    <p className="mt-4 leading-relaxed text-gray-700">
                        Non, lors de votre inscription, vous devez seulement fournir un <b>questionnaire de santé</b>. Si vous répondez "non" à toutes les questions, vous n'avez pas besoin de fournir un certificat médical. Si vous répondez "oui" à une ou plusieurs questions, vous devez fournir un certificat médical de non contre-indication à la pratique du badminton.
                    </p>
                </details>

                <details
                    className="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden"
                >
                    <summary
                        className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
                    >
                        <h2 className="font-medium">
                            Existe-t-il une différence entre les licences "compétition" et "loisir" ?
                        </h2>
                        <span className="relative h-5 w-5 shrink-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                        </span>
                    </summary>
                    <p className="mt-4 leading-relaxed text-gray-700">
                        Non, nous ne faisons pas de différence entre les licences "compétition" et "loisir". Même si vous ne souhaitez pas participer aux compétitions pour le moment, elles vous sont ouvertes si vous changez d'avis.
                    </p>
                </details>

            </div>

            <p className={"mt-5"}>Vous n'avez pas trouvé de réponse à votre question ? <a className={"text-flavigny underline"}
                                                                      href="/contact"><b>Contactez-nous !</b></a></p>
            <li>Par mail : contact@asfbad54.fr</li>
            <li>Par téléphone : 06 37 50 26 63</li>
            <li>Ou venez nous voir au club pendant nos <a className={"text-flavigny underline"}
                                                          href="/horaires"><b>horaires</b></a> d'ouverture.
            </li>

        </div>
    );
}

export default Register;