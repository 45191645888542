import React from "react";

function TournamentDisplay({tournament}) {

    function checkTournamentStatus(date, time_start, time_end) {
        // if date is in the future return 1
        // if date is in the past return -1
        // else return 0

        let today = new Date();

        let tournament_date = new Date(date);
        tournament_date.setHours(time_start.split(":")[0], time_start.split(":")[1], time_start.split(":")[2], 0);

        if (tournament_date > today) {
            return 1;
        }
        else {
            // check if current time is between time_start and time_end (time string format: "10:00:00")
            let tournament_time_end = new Date(date);
            tournament_time_end.setHours(time_end.split(":")[0], time_end.split(":")[1], time_end.split(":")[2], 0);
            if (today >= tournament_date && today <= tournament_time_end) {
                return 0;
            }
            else {
                return -1;
            }
        }
    }

    const tournament_status = checkTournamentStatus(tournament.date, tournament.time_start, tournament.time_end);

    return (
        <article className="rounded-xl bg-white p-4 ring ring-indigo-50 sm:p-6 lg:p-8">
            <div className="flex items-start sm:gap-8">
                <div>
                    {(tournament_status === 1) && <strong
                        className="rounded border border-orange-500 bg-orange-500 px-3 py-1.5 text-[10px] font-medium text-white"
                    >
                        Inscription en cours
                    </strong>}

                    {(tournament_status === 0) && <strong
                        className="rounded border border-green-600 bg-green-600 px-3 py-1.5 text-[10px] font-medium text-white"
                    >
                        Tournoi en cours
                    </strong>}

                    {(tournament_status === -1) && <strong
                        className="rounded border border-red-500 bg-red-500 px-3 py-1.5 text-[10px] font-medium text-white"
                    >
                        Tournoi terminé
                    </strong>}

                    <h3 className="mt-4 text-lg font-medium sm:text-xl">
                        <a href={"/tournois/" + tournament.id} className="hover:underline"> {tournament.name} </a>
                    </h3>

                    <p className="mt-1 text-sm text-gray-700">
                        {tournament.description}
                    </p>

                    <div className="mt-4 flex items-center gap-2">
                        <div className="flex items-center gap-1 text-gray-500">
                            <svg
                                className="h-4 w-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                            </svg>

                            <p className="text-xs font-medium"> {new Date(tournament.date).toLocaleString().substring(0, 10) } </p>
                        </div>
                        <p className="text-xs font-medium"> {tournament.time_start.substring(0, 5) } </p>
                        <span aria-hidden="true">&middot;</span>
                        <p className="text-xs font-medium"> {tournament.time_end.substring(0, 5) } </p>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default TournamentDisplay;