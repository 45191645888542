import React, {useEffect, useState} from 'react';

// Component (here it's a view)
function Header(props) {

    return (
        <div>
            {props.children}
        </div>
    )
}

export default Header;
